<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-09 20:00:08
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-12 14:56:20
-->
<template>
  <div class="user">
    <div class="user-content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Index'
  }
</script>

<style lang="less" scoped>
  .user {
    width: 100%;
    height: 100%;

    .user-content-wrapper {
      width: 100%;
      min-height: 100%;
      // background: url("../../assets/images/login-bg.jpg") no-repeat 50%;
      background-size: cover;
    }
  }
</style>
